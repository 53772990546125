import { graphql } from "gatsby";
import React from "react";
import BlogPost from "../components/BlogPost";
import GlobalStyles from "../styles/GlobalStyles";
import SEO from "../components/SEO";
// import { toPlainText } from '../lib/helpers';

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      description
      author {
        name
      }
      categories {
        _id
        title
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
      title
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const BlogPostTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.post;

  return (
    <>
      <GlobalStyles />
      {/* {errors && <SEO title="GraphQL Error" />} */}
      {post && (
        <SEO
          title={post.title || "Untitled"}
          description={post.description}
          image={post.mainImage}
        />
      )}

      {post && <BlogPost {...post} />}
    </>
  );
};

export default BlogPostTemplate;

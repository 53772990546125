import React from "react";
import PortableText from "./portableText";
import { buildImageObj } from "../utils/helpers";
import { imageUrlFor } from "../utils/image-url";
import styled from "styled-components";
import HeaderVenture from "./Nav";
import Footer from "../components/Footer";

const BlogPostStyled = styled.section`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  iframe {
    width: 100%;
  }
  .container {
    max-width: 1078px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 20px;
  }
  svg.main {
    position: absolute;
    top: 0;
    z-index: -1;
  }
  img {
    width: 100%;
    max-height: 500px;
    /* margin: 48px 0; */
  }
  article {
    max-width: 720px;
    header {
      margin-top: 65px;
      margin-bottom: 40px;
    }

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: #101828;
      margin-top: 48px;
      margin-bottom: 24px;
    }
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
      margin-top: 48px;
      margin-bottom: 16px;
    }

    .author,
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #667085;
      :not(p:first-child) {
        margin-bottom: 28px;
      }
    }
    .author {
      margin-top: 4px;
    }

    a {
      color: #667085;
      text-decoration: underline;
    }
  }
  header {
    width: 100%;
    text-align: center;

    .category {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      text-align: center;

      color: #439eab;
      width: fit-content;
      padding: 2px 5px;
      border: 2px solid #439eab;
      margin-bottom: 9px;
      margin-left: auto;
      margin-right: auto;
    }
    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #101828;
    }
  }
`;

function BlogPost(props) {
  const {
    _rawBody,
    title,
    mainImage,
    categories,
    author,
  } = props;

  return (
    <BlogPostStyled>
      <svg
        className="main"
        height="707"
        width="100%"
        // viewBox="0 0 1440 707"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          // y="-9"
          width="100%"
          height="716"
          fill="url(#paint0_linear_687_81699)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_687_81699"
            x1="720"
            // y1="-9"
            x2="720"
            y2="707"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B2D9FB" />
            <stop
              offset="1"
              stopColor="white"
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
      </svg>

      <div>
        <HeaderVenture />
        <section className="container">
          <article>
            <header>
              <div className="category">
                {categories.title}
              </div>
              <h1>{title}</h1>
              <div className="author">
                {author.name}
              </div>
            </header>

            {mainImage && mainImage.asset && (
              <div className={mainImage}>
                <img
                  src={imageUrlFor(
                    buildImageObj(mainImage)
                  )
                    .width(1200)
                    .height(
                      Math.floor((9 / 16) * 1200)
                    )
                    .fit("crop")
                    .auto("format")
                    .url()}
                  alt=""
                />
              </div>
            )}
            <div>
              <div>
                {_rawBody && (
                  <PortableText
                    blocks={_rawBody}
                  />
                )}
              </div>
            </div>
          </article>
        </section>
      </div>

      <Footer />
    </BlogPostStyled>
  );
}

export default BlogPost;

import React from 'react';
import BasePortableText from '@sanity/block-content-to-react';
import clientConfig from '../../client-config';
// import serializers from './serializers';
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'


const serializers = {
  types: {
    youtube: ({node}) => {
      const { url } = node
      const id = getYouTubeId(url)
      return (<YouTube videoId={id} />)
    }
  }
}

const PortableText = ({ blocks }) => (
  <BasePortableText
    blocks={blocks}
    serializers={serializers}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...clientConfig.sanity}
  />
);

export default PortableText;
